/*
 * @Author: Ben 550461173@qq.com
 * @Date: 2024-07-26 15:27:51
 * @LastEditors: Ben 550461173@qq.com
 * @LastEditTime: 2024-07-26 18:30:00
 * @FilePath: \digital-ocms-kacontent-mobile\src\api\modules\user.ts
 * @Description:  */
import { http } from '@/utils/http/axios'
import { Apis } from '@/enums/apis'
import { RequestEnum } from '@/enums/httpEnum'
import type { IUserState } from '@/typings/user'

/**
 * 用户授权
 * @param code  授权码
 * @param debug 是否debug调试
 * @returns     授权结果
 */
export async function userAuth(code: string, debug: boolean) {
    return await http.request<IUserState>({
        url: Apis.Auth,
        method: RequestEnum.GET,
        params: { code, debug },
    })
}
