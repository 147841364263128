/*
 * @Author: Ben 550461173@qq.com
 * @Date: 2024-07-22 22:42:35
 * @LastEditors: Ben 550461173@qq.com
 * @LastEditTime: 2024-07-24 09:10:26
 * @FilePath: \digital-ocms-kacontent-mobile\src\enums\httpEnum.ts
 * @Description:
 */
/**
 * @description: 请求结果集
 */
export enum ResultEnum {
    SUCCESS = '200',
    TOKEN_EXPIRED = '401',
    ERROR = '300',
    TIMEOUT = 10042,
    TYPE = 'success',
}

/**
 * @description: 请求方法
 */
export enum RequestEnum {
    GET = 'GET',
    POST = 'POST',
    PATCH = 'PATCH',
    PUT = 'PUT',
    DELETE = 'DELETE',
}

/**
 * @description:  常用的contentTyp类型
 */
export enum ContentTypeEnum {
    // json
    JSON = 'application/json;charset=UTF-8',
    // json
    TEXT = 'text/plain;charset=UTF-8',
    // form-data 一般配合qs
    FORM_URLENCODED = 'application/x-www-form-urlencoded;charset=UTF-8',
    // form-data  上传
    FORM_DATA = 'multipart/form-data;charset=UTF-8',
}
