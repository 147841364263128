/*
 * @Author: Ben Ben.Xiao@cognizant.com
 * @Date: 2024-08-06 16:17:49
 * @LastEditors: Ben Ben.Xiao@cognizant.com
 * @LastEditTime: 2024-08-09 22:24:32
 * @FilePath: /digital-ocms-kacontent-mobile/src/enums/pageEnum.ts
 * @Description:
 */
/* eslint-disable ts/no-duplicate-enum-values */
export enum PageEnum {

    // 授权
    BASE_AUTH = '/auth',
    BASE_AUTH_NAME = 'Auth',
    BASE_AUTH_CALLBACK = 'AuthCallback',

    // 首页
    BASE_HOME = '/home',
    BASE_HOME_REDIRECT = '/home',

    BASE_SHARE = 'Share',

    // 错误
    ERROR_PAGE_NAME = 'ErrorPage',
}
